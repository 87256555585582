

























































































































import { Component, Vue } from 'vue-property-decorator';

import '../scss/PopupAddProjectMember.scss';
import { IMemberRole, IProject, IProjectState } from '@store/modules/project/Interfaces';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import { getData } from '@utility/dadata';
import { formatDate } from '@utility/formatDate';
import { makeFocusInput } from "@utility/makeFocusInput";

const NSProject = namespace('storeProject');
const aboutProjectNamespace = namespace('storeAboutProject');

@Component({
    name: 'AddMemberJuristicForm',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseFileInput: () => import('@components/BaseFileInput/BaseFileInput.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    }
})

export default class AddMemberJuristicForm extends Vue {
    $refs!: {
        innField: HTMLFormElement,
        companyTitleField: HTMLFormElement,
        addMemberJuristicForm: HTMLElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.State((state: IProjectState) => state.memberRolesJuristic) memberRolesJuristic!: IMemberRole[];
    @aboutProjectNamespace.Action(AboutProjectActions.A_CREATE_MEMBER_JURISTIC) createNewMemberJuristic!: (payload) => Promise<void>

    fields: any = {
        fullTitle: '',
        title: '',
        inn: '',
        role: '',
        startDate: '',
        endDate: '',
    };
    additionalFields: any = {
        kpp: {
            title: 'КПП',
            value: '',
        },
        ogrn: {
            title: 'ОГРН',
            value: '',
        },
        ogrnDate: {
            title: 'Дата выдачи ОГРН',
            value: '',
        },
        address: {
            title: 'Юр. адрес',
            value: '',
        },
        phones: {
            title: 'Телефон(ы)',
            value: [],
        },
    };
    errors = {
        fullTitle: false,
        title: false,
        inn: false,
        role: false,
    };
    innLengthMin: number = 10;
    innLengthMax: number = 12;
    realtimeValidation = false;
    dadataKey: string = 'f1be7d0d02603c2ea36c7f512f6c16764c8f01ed';
    showAdditionalFields: boolean = false;
    showNotFoundDataVieInn: boolean = false;
    errorText: string = '';

    @Watch('fields.inn')
    watchFieldInn() {
        this.showNotFoundDataVieInn = false;
        if (this.fields.inn.length > this.innLengthMax) {
            let output = '';
            for (let i = 0; i < this.innLengthMax; i++) {
                output += this.fields.inn[i];
            }
            this.$refs.innField.$el.querySelector('input').value = output;
            this.fields.inn = output;
        }
    }

    mounted() {
        makeFocusInput(this.$refs.addMemberJuristicForm);
    }

    get innIsFilledCurrent() {
        return this.fields.inn.length === this.innLengthMin || this.fields.inn.length === this.innLengthMax;
    }

    get titleForSelectRole() {
        if (this.fields.role) {
            const roleItem = this.memberRolesJuristic.filter(item => item.uuid === this.fields.role);
            if (roleItem.length) {
                return roleItem[0].value;
            }
        }
        return 'Выберите роль организации';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    getDataViaInn() {
        getData(this.dadataKey, this.fields.inn).then(data => {
            const items = data.suggestions;
            if (items.length) {
                const foundedObject = items[0];
                const foundedObjectTitle = foundedObject.value;
                const foundedObjectPhones = foundedObject.data.phones;
                const phonesList: string[] = [];
                if (foundedObjectPhones && foundedObjectPhones.length) {
                    foundedObjectPhones.forEach(phone => {
                        phonesList.push(phone.value || phone.unrestricted_value);
                    });
                }

                this.additionalFields.kpp.value = foundedObject.data.kpp;
                this.additionalFields.ogrn.value = foundedObject.data.ogrn;
                this.additionalFields.ogrnDate.value = formatDate({ date: new Date(foundedObject.data.ogrn_date), format: 'yyyy-mm-dd'});
                this.additionalFields.address.value = foundedObject.data.address.unrestricted_value;
                this.additionalFields.phones.value = phonesList;

                this.$refs.companyTitleField.$el.querySelector('input').value = foundedObjectTitle;
                this.fields.fullTitle = foundedObjectTitle;

                this.showAdditionalFields = true;
                this.showNotFoundDataVieInn = false;
            }
            else {
                this.showNotFoundDataVieInn = true;
            }
        })
    }

    selectRoleJuristic(e) {
        this.fields.role = e.uuid;
        this.checkValidation();
    }

    validateFields() {
        this.errors.fullTitle = !this.fields.fullTitle;
        this.errors.title = !this.fields.title;
        this.errors.inn = !this.fields.inn;
        this.errors.role = !this.fields.role;
        this.errorText = 'Вы не заполнили одно или несколько обязательных полей';

        if (!this.errorExists) {
            this.checkInnValidate();
        }

        this.realtimeValidation = true;
    }

    checkInnValidate(): void {
        if (this.fields.inn.length < 10 || this.fields.inn.length === 11) {
            this.errorText = 'ИНН должен быть из 10 или 12 цифр';
            this.errors.inn = true;
        } else {
            this.errorText = '';
            this.errors.inn = false;
        }
    }

    checkSend() {
        this.validateFields();
        this.changeBtnDisabled(true);
        if (!this.errorExists) {
            let payload = {
                fullName: this.fields.fullTitle,
                appId: this.projectData.id,
                name: this.fields.title,
                inn: this.fields.inn,
                roleId: this.fields.role,
                phones: [],
            }
            if (this.fields.startDate) {
                payload['startDate'] = formatDate({ date: new Date(this.fields.startDate), format: 'yyyy-mm-dd'});
            }
            if (this.fields.endDate) {
                payload['endDate'] = formatDate({ date: new Date(this.fields.endDate), format: 'yyyy-mm-dd'});
            }
            if (this.showAdditionalFields) {
                payload['kpp'] = this.additionalFields.kpp.value;
                payload['ogrn'] = this.additionalFields.ogrn.value;
                payload['ogrnDate'] = this.additionalFields.ogrnDate.value;
                payload['address'] = this.additionalFields.address.value;
                payload['phones'] = this.additionalFields.phones.value;
            }
            this.createNewMemberJuristic(payload)
                .then(() => {
                    this.$emit('confirm-add-member');
                    this.resetData();
            })
        }
        this.changeBtnDisabled(false);
    }

    resetData() {
        this.fields = {
            fullTitle: '',
            title: '',
            inn: '',
            role: '',
            startDate: '',
            endDate: '',
        }
        this.realtimeValidation = false;
        this.errors = {
            fullTitle: false,
            title: false,
            inn: false,
            role: false,
        };
        this.additionalFields = {
            kpp: {
                title: 'КПП',
                value: '',
            },
            ogrn: {
                title: 'ОГРН',
                value: '',
            },
            ogrnDate: {
                title: 'Дата выдачи ОГРН',
                value: '',
            },
            address: {
                title: 'Юр. адрес',
                value: '',
            },
            phones: {
                title: 'Телефон(ы)',
                value: [],
            },
        };
        this.showAdditionalFields = false;
    }

    changeBtnDisabled(val) {
        this.$emit('change-btn-disabled', val);
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }
}
